<template>
  <div class="cred-img-container">
    <!-- <div class="buttons">
      <el-button type="info" icon="el-icon-refresh-left" @click="$router.go(-1)">返回</el-button>
      <div style="margin: 10px 0;"></div>
      <el-button :loading="btnLoading" type="success" icon="el-icon-takeaway-box" @click="handleDown">下载</el-button>
    </div> -->
    <div class="img-wrapper" id="wrapper" v-if="type === 2">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <div class="name2" v-if="!realSrc">{{ detail.name }}</div>
      <div class="info2" v-if="!realSrc">
        <span>{{ detail.company }}</span>
        <span class="item2">{{ detail.htNumber }}</span>
        <span class="item3"
          >{{ detail.htStartTime }} ~ {{ detail.htEndTime }}</span
        >
      </div>
      <div class="time2" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div>
    </div>

    <div class="img-wrapper" id="wrapper" v-else-if="type === 1">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item item1">{{ detail.name }}</span>
        <span class="item item2">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item3">{{
          detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item4">{{ detail.code }}</span>
        <span class="item item5" v-if="detail.startTime"
          >{{ detail.startTime }} ~ {{ detail.endTime }}</span
        >
      </div>
      <div class="name11" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time11" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <div class="img-wrapper" id="wrapper" v-else-if="type === 12">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item item1">{{ detail.name }}</span>
        <span class="item item2">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item3">{{
          detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item4">{{ detail.code }}</span>
        <span class="item item5" v-if="detail.startTime"
          >{{ detail.startTime }} ~ {{ detail.endTime }}</span
        >
      </div>
      <div class="name12" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time11" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <!-- 幼儿篮球裁判员 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 0">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo11" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item item31">{{ detail.name }}</span>
        <span class="item item32">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item36">{{ detail.deptName }}</span>
        <span class="item item33">{{ detail.idCard }}</span>
        <span class="item item34">{{
          detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item35">{{ detail.code }}</span>
      </div>
      <!-- <div class="name0" v-if="!realSrc">{{ detail.name }}</div> -->
      <div class="time12" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <!-- weac-B -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 8">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo8" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item item30">{{ detail.name }}</span>
        <span class="item item26">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item27">{{
          detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item28">{{ detail.code }}</span>
        <span class="item item29" v-if="detail.startTime"
          >{{ detail.startTime }} ~ {{ detail.endTime }}</span
        >
      </div>
      <div class="name8" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time8" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <!-- weac-s -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 9">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo9" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item">{{ detail.name }}</span>
        <span class="item item22">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item23">{{
          detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item24">{{ detail.code }}</span>
        <span class="item item25" v-if="detail.startTime"
          >{{ detail.startTime }} ~ {{ detail.endTime }}</span
        >
      </div>
      <div class="name9" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time9" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <!-- weac-a -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 10">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo10" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item">{{ detail.name }}</span>
        <span class="item item22">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item23">{{
          detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item24">{{ detail.code }}</span>
        <span class="item item25" v-if="detail.startTime"
          >{{ detail.startTime }} ~ {{ detail.endTime }}</span
        >
      </div>
      <div class="name10" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time10" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <div class="img-wrapper2" id="wrapper" v-else-if="type === 3">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo" id="photo" />
      <div class="name" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div>
      <div class="qishu" v-if="!realSrc">{{ detail.periodical }}</div>
      <div class="time3" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <div class="img-wrapper2" id="wrapper" v-else-if="type === 6">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo6" id="photo" />
      <div class="name6" v-if="!realSrc">{{ detail.name }}</div>
      <div class="code6" v-if="!realSrc">{{ detail.code }}</div>
      <!-- <div class="time" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div>
      <div class="qishu" v-if="!realSrc">{{ detail.periodical }}</div> -->
      <!-- <div class="time6" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div> -->
    </div>

    <div class="img-wrapper2" id="wrapper" v-else-if="type === 4">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo4" id="photo" />
      <div class="party4" v-if="!realSrc">{{ detail.deptName }}</div>
      <div class="name4" v-if="!realSrc">{{ detail.name }}</div>
      <div class="code4" v-if="!realSrc">{{ detail.code }}</div>
      <!-- <div class="time">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
      </div> -->
    </div>

    <!-- 星绳童证书 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 7">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo7" id="photo" />
      <div class="info" v-if="!realSrc">
        <span class="item item6">{{ detail.name }}</span>
        <span class="item item7">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span
          :class="{
            item8: detail.deptName.length <= 11,
            item14: detail.deptName.length > 11,
          }"
          >{{ detail.deptName }}</span
        >
        <span class="item item9">{{ detail.code }}</span>
        <span class="item item10" v-if="detail.startTime">
          <span class="item11"
            >{{ startYear }}.{{ startMonth }}.{{ startDate }}</span
          >
          <span class="item12">~</span>
          <span class="item13">{{ endYear }}.{{ endMonth }}.{{ endDate }}</span>
        </span>
      </div>
      <div class="name3" v-if="!realSrc">{{ detail.name }}</div>
      <div class="time3" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{ time.date }}</span>
      </div>
    </div>

    <!-- 幼儿篮球教师资格证C证 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 11">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo12" id="photo" />
      <div class="info" v-if="!realSrc">
        <!-- <span class="item item37">{{ detail.name }}</span> -->
        <span v-if="detail.name.length <= 8" class="item item37">{{
          detail.name
        }}</span>
        <span v-else-if="detail.name.length > 8" class="item item41">{{
          detail.name
        }}</span>
        <span class="item item38">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item39">{{
          detail.createTime.substring(0, 10) == "2023-03-30" ||
          detail.createTime.substring(0, 10) == "2023-03-31"
            ? "2023-03-24"
            : detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item40">{{ detail.code }}</span>
      </div>
      <div class="name8" v-if="!realSrc && detail.name.length<=4">{{ detail.name }}</div>
      <div class="name11" v-if="!realSrc && detail.name.length>4">{{ detail.name }}</div>
      <div class="time8" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{
          time.year == "2023" &&
          time.month == "03" &&
          (time.date == "30" || time.date == "31")
            ? "24"
            : time.date
        }}</span>
      </div>
    </div>

    <!-- 华蒙星教练员C证 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 13">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo13" id="photo" />
      <div class="info" v-if="!realSrc">
        <span v-if="detail.name.length <= 8" class="item item42">{{
          detail.name
        }}</span>
        <span v-else-if="detail.name.length > 8" class="item item41">{{
          detail.name
        }}</span>
        <span class="item item43">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item44">{{
          detail.createTime.substring(0, 10) == "2023-03-30" ||
          detail.createTime.substring(0, 10) == "2023-03-31"
            ? "2023-03-24"
            : detail.createTime && detail.createTime.substring(0, 10)
        }}</span>
        <span class="item item45">{{ detail.code }}</span>
      </div>
      <div class="name8" v-if="!realSrc && detail.name.length<=4">{{ detail.name }}</div>
      <div class="name11" v-if="!realSrc && detail.name.length>4">{{ detail.name }}</div>
      <div class="time8" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{
          time.year == "2023" &&
          time.month == "03" &&
          (time.date == "30" || time.date == "31")
            ? "24"
            : time.date
        }}</span>
      </div>
    </div>

    <!-- 华蒙星幼儿体育研修班 -->
    <div class="img-wrapper" id="wrapper" v-else-if="type === 14">
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
      <img :src="photoPath" class="photo13" id="photo" />
      <div class="info" v-if="!realSrc">
        <span v-if="detail.name.length <= 8" class="item item42">{{
          detail.name
        }}</span>
        <span v-else-if="detail.name.length > 8" class="item item41">{{
          detail.name
        }}</span>
        <span class="item item43">{{ detail.sex === 1 ? "男" : "女" }}</span>
        <span class="item item44">{{
          detail.idCard
        }}</span>
        <span class="item item45">{{ detail.code }}</span>
      </div>
      <div class="name8" v-if="!realSrc && detail.name.length<=4">{{ detail.name }}</div>
      <div class="name11" v-if="!realSrc && detail.name.length>4">{{ detail.name }}</div>
      <div class="time8" v-if="!realSrc">
        <span>{{ time.year }}</span>
        <span>{{ time.month }}</span>
        <span>{{
          time.year == "2023" &&
          time.month == "03" &&
          (time.date == "30" || time.date == "31")
            ? "24"
            : time.date
        }}</span>
      </div>
    </div>

    <div class="img-wrapper" id="wrapper" v-else>
      <img v-if="realSrc" :src="realSrc" class="base" />
      <img
        v-else
        :src="require('../../assets/zs/' + detail.categoryName + '.jpg')"
        class="base"
      />
    </div>
  </div>
</template>

<script>
// import html2canvas from 'html2canvas'
import html2canvas from "../../../public/lib/html2canvas";

export default {
  data() {
    return {
      type: 2,
      realSrc: "",
      cover: null,
      detail: {},
      photoPath: "",
      time: {},
      startYear: "",
      startMonth: "",
      startDate: "",
      endYear: "",
      endMonth: "",
      endDate: "",
    };
  },

  created() {
    this.getDetail();
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 3000,
    });
  },

  methods: {
    getDetail() {
      this.detail = JSON.parse(this.$route.query.info);
      if (this.detail.categoryName.includes("幼儿篮球裁判员")) {
        this.type = 0;
        // console.log(this.detail);
      }
      if (
        this.detail.categoryName.includes("AC认证") ||
        this.detail.categoryName.includes("WEAC-B") ||
        this.detail.categoryName.includes("公益培训")
      ) {
        this.type = 8;
      } else if (this.detail.categoryName.includes("WEAC-S")) {
        this.type = 9;
      } else if (this.detail.categoryName.includes("WEAC-A")) {
        this.type = 10;
      } else if (
        this.detail.categoryName.includes("认证培训") 
        // || this.detail.categoryName.includes("WEAC")
      ) {
        this.type = 1;
      }
      else if (
        this.detail.categoryName.includes("初级") ||
        this.detail.categoryName.includes("一星")
      ) {
        this.type = 3;
      }
      else if (this.detail.categoryName === "幼儿篮球教师资格证书") {
        this.type = 4;
      }
      else if (this.detail.categoryName.includes("小小CBA")) {
        this.type = 5;
      }
      else if (this.detail.categoryName.includes("跳绳")) {
        this.type = 6;
      }
      else if (this.detail.categoryName.includes("星绳童")) {
        this.type = 7;
      }
      else if (this.detail.categoryName.includes("C证")) {
        this.type = 11;
      }
      else if (this.detail.categoryName.includes("WEAC-T")) {
        this.type = 12;
      }
      else if (this.detail.categoryName.includes("教练员证书")) {
        this.type = 13;
      }else if (this.detail.categoryName.includes("幼儿体育研修班")) {
        this.type = 14;
      }
      this.time = {
        year: this.detail.createTime.substring(0, 4),
        month: this.detail.createTime.substring(5, 7),
        date: this.detail.createTime.substring(8, 10),
      };
      console.log(this.type);
      if (this.type === 7) {
        this.startYear = this.detail.createTime.substring(0, 4);
        this.startMonth = this.detail.createTime.substring(5, 7);
        this.startDate = this.detail.createTime.substring(8, 10);
        this.endYear = this.detail.endTime.substring(0, 4);
        this.endMonth = this.detail.endTime.substring(5, 7);
        this.endDate = this.detail.endTime.substring(8, 10);
      }
      if (this.detail.icon) {
        this.photoPath =
          process.env.VUE_APP_baseApi +
          "/upload/loadImgDataByFileName?fileName=" +
          this.detail.icon;
        // this.imgToBase64()
        setTimeout(() => {
          this.handleDown();
        }, 1000);
      }
    },

    // 将在线图片转为base64
    imgToBase64() {
      // setTimeout(() => {
      //   let image = document.getElementById('photo')
      //   console.log(image)
      //   let canvas = document.getElementById('myCanvas')
      //   let ctx = canvas.getContext('2d')
      //   let w = image.width
      //   let h = image.height
      //   ctx.drawImage(image, 0, 0, w, h)
      // }, 1000)
      // setTimeout(() => {
      //   this.handleDown()
      // }, 1000)
      let that = this;
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let image = document.getElementById("photo");
      // let image = new Image()
      // image.src = photoPath
      // image.src = src + '?v=' + Math.random() // 处理缓存
      // image.crossOrigin = '*' // 支持跨域图片
      setTimeout(() => {
        // image.onload = () => { // iOS上onload事件无效
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        alert(canvas.width);
        that.photoPath = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
        // }
      }, 1000);
      setTimeout(() => {
        that.handleDown();
      }, 1000); // iOS上设置延迟才成功，但图片还是空白
      // setTimeout(() => {
      //   (window.html2canvas || html2canvas)(document.getElementById('photo')).then(canvas => {
      //     this.photoPath = canvas.toDataURL('image/png')
      //     this.handleDown()
      //   }).catch(err => {
      //     console.log(err)
      //   })
      // }, 1000)
    },

    // html导出图片
    handleDown() {
      (window.html2canvas || html2canvas)(document.getElementById("wrapper"))
        .then((canvas) => {
          this.realSrc = canvas.toDataURL("image/png");
          // let a = document.createElement('a')
          // let dom = document.body.appendChild(canvas)
          // dom.style.display = 'none'
          // a.style.display = 'none'
          // document.body.removeChild(dom)
          // let blob = this.dataURLToBlob(dom.toDataURL('image/png'))
          // a.setAttribute('href', URL.createObjectURL(blob))
          // a.setAttribute('download', '证书.png')
          // document.body.appendChild(a)
          // a.click()
          // URL.revokeObjectURL(blob)
          // document.body.removeChild(a)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    dataURLToBlob(dataurl) {
      //ie 图片转格式
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
};
</script>

<style lang="scss" scoped>
.cred-img-container {
  display: flex;

  .buttons {
  }

  .img-wrapper {
    // margin-left: 20px;
    position: relative;

    .base {
      width: 855px;
      height: 605px;
    }

    .photo0 {
      position: absolute;
      top: 248px;
      left: 128px;
      width: 144px;
      height: 180px;
    }

    .photo {
      position: absolute;
      top: 170px;
      left: 150px;
      width: 172px;
      height: 200px;
    }

    .photo8 {
      position: absolute;
      top: 203px;
      left: 228px;
      width: 115px;
      height: 148px;
    }

    .photo9 {
      position: absolute;
      top: 181px;
      left: 96px;
      width: 180px;
      height: 210px;
    }

    .photo10 {
      position: absolute;
      top: 178px;
      left: 155px;
      width: 168px;
      height: 190px;
    }

    .photo11 {
      position: absolute;
      top: 242px;
      left: 130px;
      width: 147px;
      height: 183px;
    }

    .photo12 {
      position: absolute;
      top: 200px;
      left: 238px;
      width: 115px;
      height: 145px;
    }

    .photo13 {
      position: absolute;
      top: 200px;
      left: 228px;
      width: 115px;
      height: 145px;
    }


    .photo7 {
      position: absolute;
      top: 180px;
      left: 200px;
      width: 115px;
      height: 160px;
      overflow: hidden;
    }

    .item01 {
      position: absolute;
      top: 245px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 75px;
      text-align: center;
    }

    .item02 {
      position: absolute;
      top: 295px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 75px;
      text-align: center;
    }

    .item03 {
      position: absolute;
      top: 403px;
      left: 413px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 98px;
      text-align: center;
    }

    .item04 {
      position: absolute;
      top: 403px;
      left: 640px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 95px;
      text-align: center;
    }

    .item05 {
      position: absolute;
      top: 295px;
      left: 610px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 100px;
      text-align: center;
    }

    .item06 {
      position: absolute;
      top: 355px;
      left: 415px;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      width: 180px;
      text-align: center;
    }

    .name {
      width: 90px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      bottom: 190px;
      left: 130px;
    }

    .name10 {
      width: 90px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      bottom: 210px;
      left: 70px;
    }

    .name11 {
      width: 230px;
      text-align: left;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      bottom: 238px;
      left: 10px;
      // border: 1px solid;
    }

    .name12 {
      width: 230px;
      text-align: left;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      bottom: 198px;
      left: 160px;
      // border: 1px solid;
    }

    .time {
      width: 200px;
      position: absolute;
      bottom: 32px;
      right: 95px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .time11 {
      width: 200px;
      position: absolute;
      bottom: 40px;
      right: 95px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .time12 {
      width: 200px;
      position: absolute;
      bottom: 32px;
      right: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
      // border: 1px solid black;
    }

    .time8 {
      width: 200px;
      position: absolute;
      bottom: 50px;
      right: 125px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .info {
      width: 185px;
      position: absolute;
      top: 175px;
      right: 200px;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;

      .item {
        margin-top: 8px;
        text-align: center;
      }

      .item1 {
        margin-top: 0px;
        margin-left: -40px;
      }

      .item2 {
        margin-top: 15px;
        margin-left: -40px;
      }

      .item3 {
        margin-top: 19px;
        margin-left: -40px;
      }

      .item4 {
        margin-top: 20px;
      }

      .item5 {
        margin-top: 25px;
        width: 250px;
      }

      .item6 {
        font-family: "宋体";
        margin-top: 20px;
        width: 160px;
        color: rgb(12, 52, 93);
      }

      .item7 {
        font-family: "宋体";
        margin-top: 10px;
        width: 160px;
        color: rgb(12, 52, 93);
      }

      .item14 {
        font-size: 13px;
        text-align: center;
        font-family: "宋体";
        margin-top: 15px;
        margin-left: -33px;
        width: 230px;
        color: rgb(12, 52, 93);
      }

      .item8 {
        font-size: 18px;
        text-align: center;
        font-family: "宋体";
        margin-top: 9px;
        margin-left: -28px;
        width: 218px;
        color: rgb(12, 52, 93);
      }

      .item9 {
        font-family: "宋体";
        text-align: center;
        margin-top: 8px;
        margin-left: -18px;
        width: 200px;
        // border: 1px solid ;
        color: rgb(12, 52, 93);
        letter-spacing: 3px;
      }

      .item10 {
        font-family: "宋体";
        font-weight: 600;
        margin-top: 8px;
        margin-left: -5px;
        width: 230px;
        color: rgb(12, 52, 93);

        .item11 {
          width: 210px;
          margin-left: -30px;
        }

        .item12 {
          width: 230px;
          font-family: "Times New Roman";
        }

        .item13 {
          width: 230px;
        }
      }

      .item15 {
        width: 150px;
        // border: 1px solid;
        margin-top: 25px;
        // margin-left: 1px;
      }

      .item16 {
        width: 150px;
        // border: 1px solid;
        margin-top: 3px;
      }

      .item17 {
        width: 150px;
        // border: 1px solid;
        // margin-top:25px;
      }

      .item18 {
        width: 150px;
        // border: 1px solid;
        // margin-top:25px;
      }

      .item19 {
        width: 250px;
        // border: 1px solid;
        // margin-top:25px;
      }

      .item20 {
        width: 150px;
        // border: 1px solid;
        margin-top: 25px;
        // margin-left: 1px;
      }

      .item21 {
        width: 150px;
        // border: 1px solid;
        margin-top: 14px;
      }

      .item22 {
        width: 150px;
        // border: 1px solid;
        margin-top: 20px;
      }

      .item23 {
        width: 150px;
        margin-top: 24px;
        margin-left: 50px;
        // border: 1px solid;
        // margin-top:25px;
      }

      .item24 {
        width: 250px;
        // border: 1px solid;
        margin-top: 25px;
        margin-left: 10px;
      }

      .item25 {
        width: 250px;
        // border: 1px solid;
        margin-top: 22px;
        margin-left: 10px;
      }

      .item30 {
        margin-top: 25px;
        width: 155px;
        text-align: center;
      }

      .item26 {
        width: 155px;
        // margin-top: 8px;
        text-align: center;
        // border: 1px solid black;
      }

      .item27 {
        width: 155px;
        // margin-top: 8px;
        text-align: center;
      }

      .item28 {
        text-align: center;
        width: 160px;
        // border: 1px solid black;
        maigin-right: 8px;
      }

      .item29 {
        margin-top: 8px;
        margin-left: 10px;
        width: 220px;
        // border: 1px solid black;
      }

      .item31 {
        margin-top: 65px;
        margin-left: -58px;
        // margin-right:200px;
        width: 235px;
        text-align: center;
        // border:1px solid black;
      }

      .item32 {
        width: 70px;
        margin-top: 22px;
        margin-left: -52px;
        text-align: center;
        // border: 1px solid black;
      }

      .item33 {
        width: 180px;
        margin-top: -13px;
        margin-left: -52px;
        text-align: center;
        // border: 1px solid black;
      }

      .item34 {
        text-align: center;
        width: 120px;
        margin-left: -50px;
        margin-top: 18px;
        // border: 1px solid black;
      }

      .item35 {
        width: 175px;
        // border: 1px solid black;
        margin-top: -20px;
        margin-left: 165px;
      }
      .item36 {
        width: 105px;
        height: 88px;
        line-height: 28px;
        margin-left: 140px;
        margin-top: -25px;
        text-align: center;
        // border: 1px solid black;
      }

      // C证
      .item37 {
        margin-top: 25px;
        width: 155px;
        margin-top: 41px;
        margin-left: 12px;
        text-align: center;
        // border: 1px solid;
      }

      .item38 {
        width: 155px;
        margin-top: 5px;
        margin-left: 12px;
        text-align: center;
        // border: 1px solid;
      }

      .item39 {
        width: 155px;
        // margin-top: 8px;
        margin-left: 12px;
        text-align: center;
        // border: 1px solid;
      }

      .item40 {
        text-align: center;
        width: 160px;
        // border: 1px solid;
        margin-left: 12px;
      }

      .item41 {
        margin-top: 23px;
        width: 230px;
        margin-top: 45px;
        margin-left: 8px;
        text-align: left;
        // border: 1px solid;
      }

      // 教练员C证
      .item42 {
        margin-top: 25px;
        width: 155px;
        margin-left: 2px;
        text-align: center;
        // border: 1px solid;
      }

      .item43 {
        width: 155px;
        margin-top: 15px;
        margin-left: 2px;
        text-align: center;
        // border: 1px solid;
      }

      .item44 {
        width: 155px;
        margin-top: 20px;
        margin-left: 0px;
        text-align: center;
        // border: 1px solid;
      }

      .item45 {
        text-align: center;
        width: 160px;
        // border: 1px solid;
        margin-top: 20px;
        margin-left: 2px;
      }
    }

    .name3 {
      width: 90px;
      text-align: center;
      font-family: "宋体";
      font-weight: 700;
      font-size: 18px;
      color: rgb(12, 52, 93);
      position: absolute;
      bottom: 228px;
      left: 100px;
    }

    .name2 {
      font-size: 42px;
      font-weight: 600;
      // color: #D87830;
      position: absolute;
      top: 250px;
      left: 400px;
    }

    .name8 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #d87830;
      position: absolute;
      top: 360px;
      left: 85px;
      // border: 1px solid #D87830;
      width: 88px;
    }

    .name9 {
      font-size: 20px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      top: 430px;
      left: 90px;
    }

    .name10 {
      font-size: 20px;
      font-weight: 600;
      color: #d87830;
      position: absolute;
      top: 390px;
      left: 155px;
    }

    .name8 {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #d87830;
      position: absolute;
      top: 360px;
      left: 85px;
      // border: 1px solid #D87830;
      width: 88px;
    }

    .time8 {
      width: 180px;
      position: absolute;
      bottom: 60px;
      right: 135px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .time9 {
      width: 180px;
      position: absolute;
      bottom: 15px;
      right: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .time10 {
      width: 180px;
      position: absolute;
      bottom: 45px;
      right: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #d87830;
    }

    .info2 {
      position: absolute;
      bottom: 62px;
      left: 165px;
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 600;

      .item2 {
        margin-top: 3px;
      }

      .item3 {
        padding-left: 40px;
      }
    }

    .time2 {
      width: 120px;
      position: absolute;
      bottom: 55px;
      right: 115px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
    }

    .time3 {
      width: 120px;
      position: absolute;
      font-family: "宋体";
      bottom: 83px;
      right: 170px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: rgb(12, 52, 93);
    }
  }

  .img-wrapper2 {
    // margin-left: 20px;
    position: relative;

    .base {
      width: 445px;
      height: 618px;
    }

    .photo {
      width: 78px;
      height: 106px;
      position: absolute;
      top: 225px;
      left: 183px;
    }

    .photo4 {
      width: 80px;
      height: 106px;
      position: absolute;
      top: 269px;
      left: 185px;
    }

    .photo6 {
      width: 55px;
      height: 75px;
      position: absolute;
      top: 138px;
      left: 195px;
      // border: 1px solid black;
    }

    .name {
      width: 65px;
      position: absolute;
      bottom: 255px;
      left: 43px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .qishu {
      width: 23px;
      position: absolute;
      bottom: 211px;
      left: 63px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .time3 {
      width: 155px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 107px;
      right: 62px;
      font-size: 14px;
      font-weight: 600;
    }

    .party4 {
      width: 100px;
      position: absolute;
      bottom: 202px;
      left: 190px;
      font-size: 12px;
      font-weight: 600;
      color: #d87830;
      text-align: center;
    }

    .name4 {
      width: 110px;
      position: absolute;
      bottom: 180px;
      left: 180px;
      font-size: 12px;
      font-weight: 600;
      color: #d87830;
      text-align: center;
    }

    .name6 {
      width: 65px;
      position: absolute;
      bottom: 305px;
      left: 46px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .code4 {
      width: 110px;
      position: absolute;
      bottom: 133px;
      left: 200px;
      font-size: 12px;
      font-weight: 600;
      color: #d87830;
      text-align: center;
    }

    .code6 {
      position: absolute;
      bottom: 29px;
      left: 210px;
      font-size: 12px;
      font-weight: 600;
    }

    .time {
      width: 73px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 230px;
      left: 112px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .img-wrapper3 {
    // margin-left: 20px;
    position: relative;

    .base {
      max-width: 850px;
    }
  }
}
</style>
